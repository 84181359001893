import { mapActions, mapGetters } from 'vuex';
import IconProfile from '@/components/icons/IconProfile.vue';
import IconListSimple from '@/components/icons/IconListSimple.vue';
import IconListCheck from '@/components/icons/IconListCheck.vue';
import IconHeartSmall from '@/components/icons/IconHeartSmall.vue';
import IconLogout from '@/components/icons/IconLogout.vue';

export default {
  name: 'DashboardNav',
  components: { IconLogout, IconHeartSmall, IconListCheck, IconListSimple, IconProfile },
  computed: {
    ...mapGetters({
      user: 'profile/user',
    })
  },
  methods: {
    ...mapActions({
      logout: 'auth/LOGOUT'
    })
  }
};
